"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMVU = void 0;
var react_1 = require("react");
/**
 * Model-View-Update hook.
 * Ensures good practices by separating your state management from your side effects.
 */
function useMVU(defaultModel, update, notify) {
    var _a = react_1.useState([]), effects = _a[0], setEffects = _a[1];
    function reducer(model, action) {
        var _a = update(model, action), nextModel = _a[0], nextEffects = _a[1];
        setEffects(nextEffects);
        return nextModel;
    }
    var _b = react_1.useReducer(reducer, defaultModel), model = _b[0], dispatch = _b[1];
    react_1.useEffect(function () {
        if (effects.length > 0) {
            effects.forEach(function (eff) { return notify(eff, model, dispatch); });
            setEffects([]);
        }
    }, [effects, model, notify]);
    return dispatch;
}
exports.useMVU = useMVU;
