"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PinField = exports.useNotifier = exports.apply = exports.isKeyAllowed = exports.getNextFocusIdx = exports.getPrevFocusIdx = exports.defaultState = exports.defaultProps = exports.IGNORED_META_KEYS = exports.HANDLER_KEYS = exports.PROP_KEYS = exports.NO_EFFECT = void 0;
var react_1 = __importStar(require("react"));
var mvu_1 = require("../mvu");
var kb_event_1 = require("../kb-event");
var utils_1 = require("../utils");
exports.NO_EFFECT = [];
exports.PROP_KEYS = ["autoFocus", "length", "validate", "format"];
exports.HANDLER_KEYS = ["onResolveKey", "onRejectKey", "onChange", "onComplete"];
exports.IGNORED_META_KEYS = ["Alt", "Control", "Enter", "Meta", "Shift", "Tab"];
exports.defaultProps = {
    ref: { current: [] },
    length: 5,
    validate: /^[a-zA-Z0-9]$/,
    format: function (key) { return key; },
    onResolveKey: utils_1.noop,
    onRejectKey: utils_1.noop,
    onChange: utils_1.noop,
    onComplete: utils_1.noop,
};
function defaultState(props) {
    return {
        focusIdx: 0,
        codeLength: props.length,
        isKeyAllowed: isKeyAllowed(props.validate),
        fallback: null,
    };
}
exports.defaultState = defaultState;
function getPrevFocusIdx(currFocusIdx) {
    return Math.max(0, currFocusIdx - 1);
}
exports.getPrevFocusIdx = getPrevFocusIdx;
function getNextFocusIdx(currFocusIdx, lastFocusIdx) {
    if (lastFocusIdx === 0)
        return 0;
    return Math.min(currFocusIdx + 1, lastFocusIdx - 1);
}
exports.getNextFocusIdx = getNextFocusIdx;
function isKeyAllowed(predicate) {
    return function (key) {
        if (!key)
            return false;
        if (key.length > 1)
            return false;
        if (typeof predicate === "string")
            return predicate.split("").includes(key);
        if (predicate instanceof Array)
            return predicate.includes(key);
        if (predicate instanceof RegExp)
            return predicate.test(key);
        return predicate(key);
    };
}
exports.isKeyAllowed = isKeyAllowed;
function apply(state, action) {
    switch (action.type) {
        case "handle-key-down": {
            utils_1.debug("reducer", "handle-key-down", "key=" + action.key);
            switch (action.key) {
                case "Unidentified": {
                    return [__assign(__assign({}, state), { fallback: { idx: state.focusIdx, val: action.val } }), []];
                }
                case "Dead": {
                    return [
                        state,
                        [
                            { type: "set-input-val", idx: state.focusIdx, val: "" },
                            { type: "reject-key", idx: state.focusIdx, key: action.key },
                            { type: "handle-code-change" },
                        ],
                    ];
                }
                case "ArrowLeft": {
                    var prevFocusIdx = getPrevFocusIdx(state.focusIdx);
                    return [__assign(__assign({}, state), { focusIdx: prevFocusIdx }), [{ type: "focus-input", idx: prevFocusIdx }]];
                }
                case "ArrowRight": {
                    var nextFocusIdx = getNextFocusIdx(state.focusIdx, state.codeLength);
                    return [__assign(__assign({}, state), { focusIdx: nextFocusIdx }), [{ type: "focus-input", idx: nextFocusIdx }]];
                }
                case "Delete":
                case "Backspace": {
                    return [state, [{ type: "handle-delete", idx: state.focusIdx }, { type: "handle-code-change" }]];
                }
                default: {
                    if (state.isKeyAllowed(action.key)) {
                        var nextFocusIdx = getNextFocusIdx(state.focusIdx, state.codeLength);
                        return [
                            __assign(__assign({}, state), { focusIdx: nextFocusIdx }),
                            [
                                { type: "set-input-val", idx: state.focusIdx, val: action.key },
                                { type: "resolve-key", idx: state.focusIdx, key: action.key },
                                { type: "focus-input", idx: nextFocusIdx },
                                { type: "handle-code-change" },
                            ],
                        ];
                    }
                    return [state, [{ type: "reject-key", idx: state.focusIdx, key: action.key }]];
                }
            }
        }
        case "handle-key-up": {
            if (!state.fallback) {
                utils_1.debug("reducer", "handle-key-up", "ignored");
                return [state, exports.NO_EFFECT];
            }
            utils_1.debug("reducer", "handle-key-up");
            var nextState = __assign(__assign({}, state), { fallback: null });
            var effects = [];
            var _a = state.fallback, idx = _a.idx, prevVal = _a.val;
            var val = action.val;
            if (prevVal === "" && val === "") {
                effects.push({ type: "handle-delete", idx: idx }, { type: "handle-code-change" });
            }
            else if (prevVal === "" && val !== "") {
                if (state.isKeyAllowed(val)) {
                    effects.push({ type: "set-input-val", idx: idx, val: val }, { type: "resolve-key", idx: idx, key: val }, { type: "focus-input", idx: getNextFocusIdx(idx, state.codeLength) }, { type: "handle-code-change" });
                }
                else {
                    effects.push({ type: "set-input-val", idx: state.focusIdx, val: "" }, { type: "reject-key", idx: idx, key: val }, { type: "handle-code-change" });
                }
            }
            return [nextState, effects];
        }
        case "handle-paste": {
            if (!action.val.split("").slice(0, state.codeLength).every(state.isKeyAllowed)) {
                utils_1.debug("reducer", "handle-paste", "rejected,val=" + action.val);
                return [state, [{ type: "reject-key", idx: action.idx, key: action.val }]];
            }
            utils_1.debug("reducer", "handle-paste", "val=" + action.val);
            var pasteLen = Math.min(action.val.length, state.codeLength - state.focusIdx);
            var nextFocusIdx = getNextFocusIdx(pasteLen + state.focusIdx - 1, state.codeLength);
            var effects = utils_1.range(0, pasteLen).map(function (idx) { return ({
                type: "set-input-val",
                idx: idx + state.focusIdx,
                val: action.val[idx],
            }); });
            if (state.focusIdx !== nextFocusIdx) {
                effects.push({ type: "focus-input", idx: nextFocusIdx });
            }
            effects.push({ type: "handle-code-change" });
            return [__assign(__assign({}, state), { focusIdx: nextFocusIdx }), effects];
        }
        case "focus-input": {
            return [__assign(__assign({}, state), { focusIdx: action.idx }), [{ type: "focus-input", idx: action.idx }]];
        }
        default: {
            return [state, exports.NO_EFFECT];
        }
    }
}
exports.apply = apply;
function useNotifier(_a) {
    var refs = _a.refs, props = __rest(_a, ["refs"]);
    return react_1.useCallback(function (eff) {
        switch (eff.type) {
            case "focus-input": {
                utils_1.debug("notifier", "focus-input", "idx=" + eff.idx);
                refs.current[eff.idx].focus();
                break;
            }
            case "set-input-val": {
                utils_1.debug("notifier", "set-input-val", "idx=" + eff.idx + ",val=" + eff.val);
                var val = props.format(eff.val);
                refs.current[eff.idx].value = val;
                break;
            }
            case "resolve-key": {
                utils_1.debug("notifier", "resolve-key", "idx=" + eff.idx + ",key=" + eff.key);
                refs.current[eff.idx].setCustomValidity("");
                props.onResolveKey(eff.key, refs.current[eff.idx]);
                break;
            }
            case "reject-key": {
                utils_1.debug("notifier", "reject-key", "idx=" + eff.idx + ",key=" + eff.key);
                refs.current[eff.idx].setCustomValidity("Invalid key");
                props.onRejectKey(eff.key, refs.current[eff.idx]);
                break;
            }
            case "handle-delete": {
                utils_1.debug("notifier", "handle-delete", "idx=" + eff.idx);
                var prevVal = refs.current[eff.idx].value;
                refs.current[eff.idx].setCustomValidity("");
                refs.current[eff.idx].value = "";
                if (!prevVal) {
                    var prevIdx = getPrevFocusIdx(eff.idx);
                    refs.current[prevIdx].focus();
                    refs.current[prevIdx].setCustomValidity("");
                    refs.current[prevIdx].value = "";
                }
                break;
            }
            case "handle-code-change": {
                var dir = (document.documentElement.getAttribute("dir") || "ltr").toLowerCase();
                var codeArr = refs.current.map(function (r) { return r.value.trim(); });
                var code = (dir === "rtl" ? codeArr.reverse() : codeArr).join("");
                utils_1.debug("notifier", "handle-code-change", "code={" + code + "}");
                props.onChange(code);
                code.length === props.length && props.onComplete(code);
                break;
            }
            default: {
                break;
            }
        }
    }, [props, refs]);
}
exports.useNotifier = useNotifier;
exports.PinField = react_1.forwardRef(function (customProps, fwdRef) {
    var props = __assign(__assign({}, exports.defaultProps), customProps);
    var autoFocus = props.autoFocus, codeLength = props.length;
    var inputProps = utils_1.omit(__spreadArrays(exports.PROP_KEYS, exports.HANDLER_KEYS), props);
    var refs = react_1.useRef([]);
    var model = defaultState(props);
    var notify = useNotifier(__assign({ refs: refs }, props));
    var dispatch = mvu_1.useMVU(model, apply, notify);
    react_1.useImperativeHandle(fwdRef, function () { return refs.current; }, [refs]);
    function handleFocus(idx) {
        return function () {
            utils_1.debug("main", "event", "focus,idx=" + idx);
            dispatch({ type: "focus-input", idx: idx });
        };
    }
    function handleKeyDown(idx) {
        return function (evt) {
            var key = kb_event_1.getKeyFromKeyboardEvent(evt.nativeEvent);
            if (!exports.IGNORED_META_KEYS.includes(key) &&
                !evt.ctrlKey &&
                !evt.altKey &&
                !evt.metaKey &&
                evt.nativeEvent.target instanceof HTMLInputElement) {
                evt.preventDefault();
                utils_1.debug("main", "event", "key-down,idx=" + idx + ",key=" + key);
                dispatch({ type: "handle-key-down", idx: idx, key: key, val: evt.nativeEvent.target.value });
            }
            else {
                utils_1.debug("main", "event", "key-down,idx=" + idx + ",ignored-key=" + key);
            }
        };
    }
    function handleKeyUp(idx) {
        return function (evt) {
            if (evt.nativeEvent.target instanceof HTMLInputElement) {
                utils_1.debug("main", "event", "key-up,idx=" + idx);
                dispatch({ type: "handle-key-up", idx: idx, val: evt.nativeEvent.target.value });
            }
        };
    }
    function handlePaste(idx) {
        return function (evt) {
            evt.preventDefault();
            var val = evt.clipboardData.getData("Text");
            utils_1.debug("main", "event", "paste,idx=" + idx + ",val=" + val);
            dispatch({ type: "handle-paste", idx: idx, val: val });
        };
    }
    function setRefAtIndex(idx) {
        return function (ref) {
            if (ref) {
                refs.current[idx] = ref;
            }
        };
    }
    function hasAutoFocus(idx) {
        return Boolean(idx === 0 && autoFocus);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null, utils_1.range(0, codeLength).map(function (idx) { return (react_1.default.createElement("input", __assign({ type: "text", autoCapitalize: "off", autoCorrect: "off", autoComplete: "off", inputMode: "text" }, inputProps, { key: idx, ref: setRefAtIndex(idx), autoFocus: hasAutoFocus(idx), maxLength: 1, onFocus: handleFocus(idx), onKeyDown: handleKeyDown(idx), onKeyUp: handleKeyUp(idx), onPaste: handlePaste(idx) }))); })));
});
exports.default = exports.PinField;
