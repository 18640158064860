"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debug = exports.omit = exports.range = exports.noop = void 0;
function noop() {
    //
}
exports.noop = noop;
function range(start, length) {
    return Array.from({ length: length }, function (_, i) { return i + start; });
}
exports.range = range;
function omit(keys, input) {
    var _a;
    var output = Object.create({});
    for (var key in input) {
        if (!keys.includes(key)) {
            Object.assign(output, (_a = {}, _a[key] = input[key], _a));
        }
    }
    return output;
}
exports.omit = omit;
function debug(scope, fn, msg) {
    console.debug("[React PIN Field] (" + scope + ") " + fn + (msg ? ": " + msg : ""));
}
exports.debug = debug;
